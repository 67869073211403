import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  //public payuform: any = {};
  payuform = new FormGroup({
    txnid: new FormControl('1',[Validators.required]),
    udf5: new FormControl('BOLT_KIT_NODE_JS',[Validators.required]),
    surl: new FormControl('http://localhost:4200/response',[Validators.required]),
    // furl: new FormControl('http://localhost:4200/fail',[Validators.required]),
    key: new FormControl('pW0mxRV3',[Validators.required]),
    hash: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.required]),
    fname: new FormControl('',[Validators.required]),
    phone: new FormControl('',[Validators.required]),
    pinfo: new FormControl('covid-19 home care kit',[Validators.required]),
    amount: new FormControl('',[Validators.required])

  })

  get txnid(){ return this.payuform.get('txnid')};
  get surl(){ return this.payuform.get('surl')};
  // get furl(){ return this.payuform.get('furl')};
  get key(){ return this.payuform.get('key')};
  get hash(){ return this.payuform.get('hash')};
  get email(){ return this.payuform.get('email')};
  get fname(){ return this.payuform.get('fname')};
  get phone(){ return this.payuform.get('phone')};
  get pinfo(){ return this.payuform.get('pinfo')};
  get amount(){ return this.payuform.get('amount')};



  disablePaymentButton: boolean = true;
  constructor(private http: HttpClient) { }

  confirmPayment() {
    //   this.payuform.txnid = 1;
    //   this.payuform.surl = 'http://localhost:4200/success';
    //   this.payuform.furl = 'http://localhost:4200/fail';
    //   this.payuform.key = 'pW0mxRV3';
    //   this.payuform.hash = 'MIIEpQIBAAKCAQEAweEd+wryKky1unpeFEsRuWVoj8aq4zgGBNBJnADI1vr1NrFvunsbTx+fixC4N7H07lq3YM/NZLfGVyny44wGL1riKMDCq5O+Bvst5YL+3mUFYzgmf7qZSNi7VayBeflrr9Yqwftx/KphXV5apLz/mtmiaQ8Za9I61uffG9PkhZzIFxx1Li4hF/OkS6ZGtU+pynOtQ20BqS5pf7mF6DVjE/axF7m2wR/+9TAHUgsXQuLDzEfyd1K5BujdqLwOd+9njUmEs54qajSArcbjmJJQJIxNT9QfKQRg4hTodoL/Ju5K5R7Th93uTqevrN7KRV+F95/74FZZG8LXT+rQl45dNwIDAQABAoIBAQCg0rcG1kaY1fbtD7JYjQjPzBHXKcn5HzDG72wP7wqqYZPk4Xy3qIS3tpimR6E3+fMdgLyanuVWhacOCKsFG9LJl08A1RzmLHC148dnmakHG69DZYFx9HxQVA/ZenYQkthEDJh9PrGIEGEhjTINcwVoEt3I3hCNQt8yAzcsIzFtrnS8o9mbuJotl+lBFPcITEs9rxjfPXAgZu2JjtjrY8a8MQDDdFx+Z1ktJsCufDLNkiKLY8a4RjzM8kpLyiTznZYBViXOTOv7m5EX1JpshX0p6ZB3BDjovSVmES+iw0qpT3MDvsrov2zTn6unp+yN5KuqoriB3gNjZHuad5DRrZWBAoGBAOl6IHnGDBjcP0X2jwgZbntawbyCZVMD9StKM6HF9vSUqlVjyAIrX7SOOAtIQjjXtKmpBC3oLqOX2BkEtO0tAnVD0WCgUk4K/vjF0sS1rTifBFO3YOpyVxXSvjt9GvNsD8QizvHv59364d1eZMUrUW0Xvx20z3nMVXn3lTC9tCDBAoGBANSVGTlsOCy8tG9kuB7ZfxfjqgRjqo25ld75lrNJCeuHQM6aOPkTM7BRj7/s9f1rb0Z53QA8tWfex1j+0SMLq6zzZo+tBV+4TWqxmRotOALMu9prKz8pyRmGJ8xSp0Iyde06UbvIJJnYrzA2rczPa3KLIcIku+BmnrwVKcziAIP3AoGBANkwb6d4EWbK8908eF+7cKg1jAW1c1ZfVyFXHGLACnQJG87lTHigKp6GsiHkrR29o2U0QhwGYeRy62aSl1AOz7uQQ+TUHtxUpE19kQzGSIZkfpz1+KT/in1Vlvs17O1Gt//ntYUgfBxmNtg76Bf2rP+D2Jhky6Bs8ZLNOq6lujvBAoGAWbsBpbJfV8rjYq2h5iuqMNxBNI8ZO69danvxt4WZ8Vfq7Ao/CCupwr/6rGmKP8jsdFQWC/aUH97ySEdu4ZsYgO1Qmk7PGONDkkiatmjl3pCqrnxUCdpKb0KoY99ziEaiblsSiu/lOycyc2hxHiVjVjsSKc+1mffXAs7wEPJIg2sCgYEA5ick9j9cHkyqa/lbm+P1239fprXK/RUHtYDSKj7JbneoJEmwIPZ3l212nPadNgPlN5/uqmcCPY/OAKAGMQ35l19m1ig2Dw5tLDXajBHFdzO4Ch3qVXsNWt+bwei2oIHNg5RHmXbk0LjIg8IMRUCYwecfn7sgSxt28WgXCGPXTqg=';
    //   this.payuform.txnid = '1';

    // const paymentPayload = {
    //   email: this.payuform.email,
    //   name: this.payuform.firstname,
    //   phone: this.payuform.phone,
    //   productInfo: this.payuform.productinfo,
    //   amount: this.payuform.amount
    // }

    // return this.http.post<any>('http://localhost:8080/payment/payment-details', paymentPayload).subscribe(
    //   data => {
    //   console.log(data);
    //   this.payuform.txnid = data.txnId;
    //   this.payuform.surl = data.sUrl;
    //   this.payuform.furl = data.fUrl;
    //   this.payuform.key = data.key;
    //   this.payuform.hash = data.hash;
    //   this.payuform.txnid = data.txnId;
    //     this.disablePaymentButton = false;
    // }, error1 => {
    //     console.log(error1);
    //   })

    this.disablePaymentButton = false;
    const myObjStr = JSON.stringify(this.payuform.value);
    console.log(myObjStr);
    this.hash
  }

  ngOnInit() {
  }


}
