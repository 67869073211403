 <!-- ======= Top Bar ======= -->
 <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
  <div class="container d-flex">
    <div class="contact-info mr-auto">
      <i class="icofont-envelope"></i> <a href="mailto:info@kohinoorhospitals.in">info@kohinoorhospitals.in</a>
      <i class="icofont-phone"></i> +91-22-6755 6766
      <i class="icofont-google-map"></i> Kirol Road, Off LBS Road,
      Kurla (West), Mumbai 400070
    </div>
    <div class="social-links">
      <a href="https://www.instagram.com/kohinoorhospital/" class="twitter"><i class="icofont-twitter"></i></a>
      <a href="https://www.facebook.com/KohinoorHealthcareCenter/" class="facebook"><i class="icofont-facebook"></i></a>
      <a href="https://www.instagram.com/kohinoorhospital/" class="instagram"><i class="icofont-instagram"></i></a>
      <!-- <a href="#" class="skype"><i class="icofont-skype"></i></a> -->
      <a href="https://www.linkedin.com/company/1778299/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company_admin%3Bv6y0%2FkVjQ22tnhsJH8ZkiA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_company_admin-actor" class="linkedin"><i class="icofont-linkedin"></i></a>
    </div>
  </div>
</div>
