<section id="services" class="services">
  <div class="container">

    <div class="section-title">
      <h2>Services</h2>
      <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
        consectetur
        velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste
        officiis
        commodi quidem hic quas.</p>
    </div>

    <div class="col-md-4">
      <h2>PayU Payment Form</h2>
      <form [formGroup]="payuform" id="payment_form" >
        <div class="form-group">
          <label for="productInfo">Product Name:</label>
          <input formControlName="pinfo" type="text" class="form-control" id="pinfo"
          name="pinfo">
        </div>
        <div class="form-group">
          <label for="fname">Name:</label>
          <input formControlName="fname" type="text" class="form-control" id="fname"
          name="fname">
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input formControlName="email" type="email" class="form-control" id="email"
          name="email" >
        </div>
        <div class="form-group">
          <label for="email">Phone:</label>
          <input formControlName="phone" type="number" class="form-control" id="phone"
          name="phone" >
        </div>
        <div class="form-group">
          <label for="amount">Amount:</label>
          <input formControlName="amount" type="number" class="form-control" id="amount"
          name="amount" >
        </div>
        <input formControlName="surl" name="surl" id="surl" ng-model="surl" rows="2" cols="2"
        hidden >
        <!-- <input formControlName="furl" name="furl" id="furl" ng-model="furl" rows="2" cols="2"
        hidden > -->
        <input formControlName="key" name="key" id="key" ng-model="key" rows="2" cols="2"
        hidden >
        <input formControlName="hash" name="hash" id="hash" ng-model="hash" rows="2" cols="2"
        hidden >
        <input formControlName="txnid" name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2"
        hidden >
        <input formControlName="udf5" type="hidden" id="udf5" name="udf5" />
        <!-- <input formControlName="gender" name="service_provider" id="service_provider" rows="2" cols="2"
        hidden> -->
        <button *ngIf="disablePaymentButton" type="button" class="btn btn-default"
          (click)="confirmPayment()">Confirm</button>
        <button *ngIf="!disablePaymentButton" type="button" class="btn btn-danger" click="launchBOLT()">Submit</button>
      </form>
    </div>
  </div>
</section>
<script type="text/javascript"><!--
  function launchBOLT()
  {
    bolt.launch({
    key: $('#key').val(),
    txnid: $('#txnid').val(),
    hash: $('#hash').val(),
    amount: $('#amount').val(),
    firstname: $('#fname').val(),
    email: $('#email').val(),
    phone: $('#mobile').val(),
    productinfo: $('#pinfo').val(),
    udf5: $('#udf5').val(),
    surl : $('#surl').val(),
    furl: $('#surl').val()
  },{ responseHandler: function(BOLT){
    console.log( BOLT.response.txnStatus );
    if(BOLT.response.txnStatus != 'CANCEL')
    {
      //Salt is passd here for demo purpose only. For practical use keep salt at server side only.
      var fr = '<form action=\"'+$('#surl').val()+'\" method=\"post\">' +
      '<input type=\"hidden\" name=\"key\" value=\"'+BOLT.response.key+'\" />' +
      '<input type=\"hidden\" name=\"salt\" value=\"'+$('#salt').val()+'\" />' +
      '<input type=\"hidden\" name=\"txnid\" value=\"'+BOLT.response.txnid+'\" />' +
      '<input type=\"hidden\" name=\"amount\" value=\"'+BOLT.response.amount+'\" />' +
      '<input type=\"hidden\" name=\"productinfo\" value=\"'+BOLT.response.productinfo+'\" />' +
      '<input type=\"hidden\" name=\"firstname\" value=\"'+BOLT.response.firstname+'\" />' +
      '<input type=\"hidden\" name=\"email\" value=\"'+BOLT.response.email+'\" />' +
      '<input type=\"hidden\" name=\"udf5\" value=\"'+BOLT.response.udf5+'\" />' +
      '<input type=\"hidden\" name=\"mihpayid\" value=\"'+BOLT.response.mihpayid+'\" />' +
      '<input type=\"hidden\" name=\"status\" value=\"'+BOLT.response.status+'\" />' +
      '<input type=\"hidden\" name=\"hash\" value=\"'+BOLT.response.hash+'\" />' +
      '</form>';
      var form = jQuery(fr);
      jQuery('body').append(form);
      form.submit();
    }
  },
    catchException: function(BOLT){
       alert( BOLT.message );
    }
  });
  }
  //--
  </script>
